import { format, startOfDay, endOfDay, addDays, subDays } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../../utils/API";
import { Container, Content, IconButton, Tabs, Placeholder } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useForm } from "react-hook-form";
import "../../../../css/crushingscreen.scss";
import { Panel, Stack, Input, InputGroup, Heading, DateRangePicker } from "rsuite";

function Crushing() {
  const [cylinders, set_cylinders] = useState();
  const [cubes, set_cubes] = useState();
  const [date_from, set_date_from] = useState(format(new Date(), "yyyy-MM-dd"));
  const [date_range, set_date_range] = useState([startOfDay(new Date()), endOfDay(new Date())]);

  useEffect(() => {
    GetCylinderSpecimens();
    GetCubeSpecimens();
  }, [date_from]);

  async function GetCylinderSpecimens() {
    API.get("/concrete_samples/cylinder_specimens/", { params: { daterange_from: date_from } })
      .then((r) => {
        set_cylinders(
          r.data?.sort(
            (a, b) =>
              b.workorder_id - a.workorder_id ||
              a.sample_id.toString().localeCompare(b.sample_id) ||
              a.days_requested.toString().localeCompare(b.days_requested) ||
              a.specimen_id.toString().localeCompare(b.specimen_id)
          )
        );
      })
      .catch((e) => console.log(e));
  }

  async function GetCubeSpecimens() {
    API.get("/concrete_samples/cube_specimens/", { params: { daterange_from: date_from } })
      .then((r) => {
        set_cubes(
          r.data?.sort(
            (a, b) =>
              b.workorder_id - a.workorder_id ||
              a.sample_id.toString().localeCompare(b.sample_id) ||
              a.days_requested.toString().localeCompare(b.days_requested) ||
              a.specimen_id.toString().localeCompare(b.specimen_id)
          )
        );
      })
      .catch((e) => console.log(e));
  }

  const handleDateChange = (values) => {
    if (values && values[0] && values[1]) {
      set_date_from(format(values[0], "yyyy-MM-dd"));
      set_date_range(values);
    } else {
      set_date_from(format(new Date(), "yyyy-MM-dd"));
      set_date_range([startOfDay(new Date()), endOfDay(new Date())]);
    }
  };

  const getSpecimens = () => {
    GetCylinderSpecimens();
    console.log("Getting specimens for date", date_from);
  };

  const generateDailyReports = () => {
    let report_data = [...new Set(cylinders && cylinders.filter((opt) => opt.days_requested > 6).map((c) => c.workorder_id))];
    report_data = report_data.map((wo_id) => ({
      workorder_id: Number(wo_id),
      report_template: "standard_concrete_test_report",
      issue_number: 1,
      issue_date: null,
      issue_should_distribute: true,
      nata: true,
      save_file_prefix: "CON-COMP",
      signed_by: 1,
      comments: null,
    }));

    API.post(`/reports/generate/bulk`, report_data)
      .then((r) => console.log(r))
      .catch((e) => console.log(e));
  };

  const Ranges = [
    {
      label: "Tomorrow",
      value: [startOfDay(addDays(new Date(), 1)), endOfDay(addDays(new Date(), 1))],
    },
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
    },
    {
      label: "last7Days",
      value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
    },
  ];

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Crushing</Heading>
            </Stack.Item>

            <Stack>
              <InputGroup inside style={{ marginRight: "10px" }}>
                <DateRangePicker
                  style={{ width: 180, marginRight: "10px" }}
                  character=" → "
                  placeholder="Select Date Range"
                  ranges={Ranges}
                  format="dd-MM-yyyy"
                  showOneCalendar
                  onChange={handleDateChange}
                  value={date_range}
                />
              </InputGroup>

              <IconButton appearance="primary" color="green" onClick={getSpecimens} icon={<SearchIcon />}>
                Search
              </IconButton>

              <IconButton appearance="primary" color="blue" onClick={generateDailyReports} style={{ marginLeft: "10px" }}>
                Generate Reports
              </IconButton>
            </Stack>
          </Stack>
        </Panel>

        <Panel>
          <Tabs defaultActiveKey="1" appearance="subtle">
            <Tabs.Tab eventKey="1" title={`Cylinders (${cylinders?.length || 0})`}>
              <div className="column">
                <div className="container">
                  <table className="sample-table cylinder-table">
                    <thead>
                      <tr>
                        <th>WO#</th>
                        <th>Project</th>
                        <th></th>
                        <th>Mix</th>
                        <th>Sampled</th>
                        <th>Slump</th>
                        <th>Cap</th>
                        <th>Mould</th>
                        <th>#</th>
                        <th>Days</th>
                        <th>Hold</th>
                        <th>Fail T</th>
                        <th>M By</th>
                        <th>C By</th>
                        <th>Diam 1</th>
                        <th>Diam 2</th>
                        <th>Height</th>
                        <th>Mass</th>
                        <th>Load</th>
                        <th>Density</th>
                        <th>MPa</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{cylinders && cylinders.map((cylinder) => <CrushingForm key={cylinder.id} cylinder={cylinder} />)}</tbody>
                  </table>
                </div>
              </div>
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title={`Cubes (${cubes?.length || 0})`}>
              <div className="column">
                <div className="container">
                  <table className="sample-table cylinder-table">
                    <thead>
                      <tr>
                        <th>WO#</th>
                        <th>Project</th>
                        <th></th>
                        <th>Mix</th>
                        <th>Sampled</th>
                        <th>Slump</th>
                        <th>Cap</th>
                        <th>Mould</th>
                        <th>#</th>
                        <th>Days</th>
                        <th>Hold</th>
                        <th>Fail T</th>
                        <th>M By</th>
                        <th>C By</th>
                        <th>Dimension 1</th>
                        <th>Dimension 2</th>
                        <th>Dimension 3</th>
                        <th>Mass</th>
                        <th>Load</th>
                        <th>Density</th>
                        <th>MPa</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{cubes && cubes.map((cube) => <CrushingFormCube key={cube.id} cube={cube} />)}</tbody>
                  </table>
                </div>
              </div>
            </Tabs.Tab>
            <Tabs.Tab eventKey="3" title={`Cores (0)`}>
              <Placeholder.Paragraph graph="circle" />
            </Tabs.Tab>
          </Tabs>
        </Panel>
      </Content>
    </Container>
  );
}

function CrushingForm(props) {
  const { cylinder } = props;

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      id: cylinder.id,
      diameter_1: cylinder.diameter_1,
      diameter_2: cylinder.diameter_2,
      height: cylinder.height,
      mass: cylinder.mass,
      load: cylinder.load,
      density: cylinder.density,
      strength: cylinder.strength,
      fail_mode: cylinder.fail_mode ? cylinder.fail_mode : 1,
      is_complete: cylinder.diameter_1 && cylinder.diameter_2 && cylinder.height && cylinder.mass && cylinder.load && cylinder.density && cylinder.strength ? true : false,
    },
  });
  const values = watch();
  const strength = watch("strength");
  const density = watch("density");
  const is_complete = watch("is_complete");

  const onSubmit = (data) => {
    API.patch(`/concrete_samples/cylinder_specimens/${cylinder.id}`, data)
      .then((r) => data.strength && setValue("is_complete", true))
      .catch((e) => console.log(e.response.data.errors));
  };

  useEffect(() => {
    const calculate = () => {
      if (values.diameter_1 && values.diameter_2 && values.height && values.mass) {
        const avg_diam = (Number.parseFloat(values.diameter_1) + Number.parseFloat(values.diameter_2)) / 2;
        const radius = avg_diam / 2;
        const parsed_height = Number.parseInt(values.height);
        const pi = 3.1415;
        const volume = pi * radius ** 2 * parsed_height;
        const mpuv = (values.mass / volume) * 1000000;
        const mpuv_round = Math.round(mpuv / 20) * 20;
        if (mpuv_round !== values.density) {
          setValue("density", mpuv_round);
        }
      } else {
        setValue("density", undefined);
      }
      if (values.diameter_1 && values.diameter_2 && values.load) {
        const avg_diam = (Number.parseFloat(values.diameter_1) + Number.parseFloat(values.diameter_2)) / 2;
        const radius = avg_diam / 2;
        const pi = 3.1415;
        const area = pi * radius ** 2;
        let mpa = Number.parseFloat(values.load * 1000) / area;
        if (mpa < 10) {
          mpa = Math.round(mpa / 0.1) * 0.1;
          mpa = Number.parseFloat(mpa.toFixed(1));
        } else {
          mpa = Math.round(mpa / 0.5) * 0.5;
          mpa = Number.parseFloat(mpa.toFixed(1));
        }
        if (mpa !== values.strength) {
          setValue("strength", mpa);
        }
      } else {
        setValue("strength", undefined);
      }
    };

    calculate();
  }, [values, setValue]);

  return (
    <tr key={cylinder.id} className={is_complete ? "cylinder-row row-complete" : "cylinder-row"}>
      <td className="cutoff">
        <Link className="cf-link" to={`/workorders/${cylinder.workorder_id}`}>
          {cylinder.workorder_id}
        </Link>
      </td>
      <td className="cutoff">{cylinder.project_id ? <Link to={`/projects/${cylinder.project_id}`}>{cylinder.project_name}</Link> : cylinder.field_customer}</td>
      <td className="cutoff-short">{cylinder.project_suburb ? cylinder.project_suburb : cylinder.field_address_line_2}</td>
      <td className="cutoff">
        (<span className="bold">{cylinder.design_mpa}</span>) {cylinder.mix_code}
      </td>
      <td className="cutoff">{cylinder.sample_time && cylinder.sample_time.split("T")[1].substring(0, 5)}</td>
      <td className="cutoff">{cylinder.initial_slump}</td>
      <td className="cutoff">{cylinder.cap_type}</td>
      <td className="cutoff">{cylinder.mould_number}</td>
      <td className="cutoff">
        <Link to={`/tests/concrete/single_lab/new?wo=${cylinder.workorder_id}&csid=${cylinder.sample_id}`}>
          {cylinder.sheet_number ? cylinder.sheet_number : cylinder.sample_id}
        </Link>
      </td>
      <td className="cutoff">
        <span className="bold">{cylinder.days_requested}</span> {cylinder.specimen_id}
      </td>
      <td className={cylinder.on_hold ? "cell-red" : ""}>{cylinder.on_hold && "HOLD"}</td>
      <td>
        <select {...register("fail_mode")}>
          <option value={1}>N</option>
          <option value={2}>S</option>
          <option value={3}>C</option>
        </select>
      </td>
      <td>
        <select {...register("measure_tech")}>
          <option value={1}>KM</option>
        </select>
      </td>
      <td>
        <select {...register("crush_tech")}>
          <option value={1}>KM</option>
        </select>
      </td>
      <td className="cell-input">
        <input type="hidden" {...register("id", { valueAsNumber: true })} />
        <input type="number" disabled={cylinder.on_hold} {...register("diameter_1", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cylinder.on_hold} {...register("diameter_2", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cylinder.on_hold} {...register("height", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cylinder.on_hold} {...register("mass", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cylinder.on_hold} {...register("load", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="hidden" disabled={cylinder.on_hold} {...register("density", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
        <span className="b-span">{density > 0 && watch("density")}</span>
      </td>
      <td className={cylinder.days_requested >= 27 && cylinder.design_mpa > strength ? "cell-input cell-warning" : "cell-input"}>
        <input type="hidden" disabled={cylinder.on_hold} {...register("strength", { setValueAs: (v) => ((v == "") | (v == 0) ? null : parseFloat(v)) })} />
        <span className="b-span bold">{strength > 0 && watch("strength")}</span>
      </td>
      <td className="cell-input">
        <input type="button" value="OK" onClick={handleSubmit(onSubmit)} />
      </td>
    </tr>
  );
}
function CrushingFormCube(props) {
  const { cube } = props;

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      id: cube.id,
      dimension_1: cube.dimension_1,
      dimension_2: cube.dimension_2,
      dimension_3: cube.dimension_3,
      dimension_average: cube.dimension_average,
      mass: cube.mass,
      load: cube.load,
      density: cube.density,
      strength: cube.strength,
      fail_mode: cube.fail_mode ? cube.fail_mode : 1,
      is_complete: cube.dimension_1 && cube.dimension_2 && cube.dimension_3 && cube.dimension_average && cube.mass && cube.load && cube.density && cube.strength ? true : false,
    },
  });
  const values = watch();
  const strength = watch("strength");
  const density = watch("density");
  const is_complete = watch("is_complete");

  const onSubmit = (data) => {
    API.patch(`/concrete_samples/cube_specimens/${cube.id}`, data)
      .then((r) => data.strength && setValue("is_complete", true))
      .catch((e) => console.log(e.response.data.errors));
  };

  useEffect(() => {
    const calculate = () => {
      if (values.dimension_1 && values.dimension_2 && values.dimension_3 && values.mass) {
        const volume = Number.parseFloat(values.dimension_1) * Number.parseFloat(values.dimension_2) * Number.parseFloat(values.dimension_3);
        const mpuv = (values.mass / volume) * 1000000;
        const mpuv_round = Math.round(mpuv / 20) * 20;
        if (mpuv_round !== values.density) {
          setValue("density", mpuv_round);
        }
      } else {
        setValue("density", undefined);
      }
      if (values.dimension_1 && values.dimension_2 && values.load) {
        const area = Number.parseFloat(values.dimension_1) * Number.parseFloat(values.dimension_2);
        let mpa = Number.parseFloat(values.load * 1000) / area;
        if (mpa < 10) {
          mpa = Math.round(mpa / 0.1) * 0.1;
          mpa = Number.parseFloat(mpa.toFixed(1));
        } else {
          mpa = Math.round(mpa / 0.5) * 0.5;
          mpa = Number.parseFloat(mpa.toFixed(1));
        }
        if (mpa !== values.strength) {
          setValue("strength", mpa);
        }
      } else {
        setValue("strength", undefined);
      }
    };

    calculate();
  }, [values, setValue]);

  return (
    <tr key={cube.id} className={is_complete ? "cube-row row-complete" : "cube-row"}>
      <td className="cutoff">
        <Link className="cf-link" to={`/workorders/${cube.workorder_id}`}>
          {cube.workorder_id}
        </Link>
      </td>
      <td className="cutoff">{cube.project_id ? <Link to={`/projects/${cube.project_id}`}>{cube.project_name}</Link> : cube.field_customer}</td>
      <td className="cutoff-short">{cube.project_suburb ? cube.project_suburb : cube.field_address_line_2}</td>
      <td className="cutoff">
        (<span className="bold">{cube.design_mpa}</span>) {cube.mix_code}
      </td>
      <td className="cutoff">{cube.sample_time && cube.sample_time.split("T")[1].substring(0, 5)}</td>
      <td className="cutoff">{cube.initial_slump}</td>
      <td className="cutoff">{cube.cap_type}</td>
      <td className="cutoff">{cube.mould_number}</td>
      <td className="cutoff">
        <Link to={`/tests/concrete/single_lab/new?wo=${cube.workorder_id}&csid=${cube.sample_id}`}>{cube.sheet_number ? cube.sheet_number : cube.sample_id}</Link>
      </td>
      <td className="cutoff">
        <span className="bold">{cube.days_requested}</span> {cube.specimen_id}
      </td>
      <td className={cube.on_hold ? "cell-red" : ""}>{cube.on_hold && "HOLD"}</td>
      <td>
        <select {...register("fail_mode")}>
          <option value={1}>N</option>
          <option value={2}>S</option>
          <option value={3}>C</option>
        </select>
      </td>
      <td>
        <select {...register("measure_tech")}>
          <option value={1}>KM</option>
        </select>
      </td>
      <td>
        <select {...register("crush_tech")}>
          <option value={1}>KM</option>
        </select>
      </td>
      <td className="cell-input">
        <input type="hidden" {...register("id", { valueAsNumber: true })} />
        <input type="number" disabled={cube.on_hold} {...register("dimension_1", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cube.on_hold} {...register("dimension_2", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cube.on_hold} {...register("dimension_3", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cube.on_hold} {...register("mass", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
      </td>
      <td className="cell-input">
        <input type="number" disabled={cube.on_hold} {...register("load", { setValueAs: (v) => (v === "" ? null : parseFloat(v)) })} />
      </td>
      <td className="cell-input">
        <input type="hidden" disabled={cube.on_hold} {...register("density", { setValueAs: (v) => (v === "" ? null : parseInt(v)) })} />
        <span className="b-span">{density > 0 && watch("density")}</span>
      </td>
      <td className={cube.days_requested >= 27 && cube.design_mpa > strength ? "cell-input cell-warning" : "cell-input"}>
        <input type="hidden" disabled={cube.on_hold} {...register("strength", { setValueAs: (v) => ((v == "") | (v == 0) ? null : parseFloat(v)) })} />
        <span className="b-span bold">{strength > 0 && watch("strength")}</span>
      </td>
      <td className="cell-input">
        <input type="button" value="OK" onClick={handleSubmit(onSubmit)} />
      </td>
    </tr>
  );
}

export default Crushing;
