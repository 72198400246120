import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import WorkorderlistBilling from "../../components/WorkorderListBilling";
import API from "../../utils/API";

import { format, startOfDay, endOfDay, subDays, addDays, startOfMonth, endOfMonth, subMonths, addMonths } from "date-fns";
import { DateRangePicker, Stack, Panel, FlexboxGrid, IconButton, Button, ButtonToolbar, ButtonGroup, Heading, Text, SelectPicker, Form, CheckPicker, Radio, RadioGroup, HStack, List } from "rsuite";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import { RadioTile, RadioTileGroup, useMediaQuery } from "rsuite";
import { Icon } from "@rsuite/icons";
import GearIcon from "@rsuite/icons/Gear";
import { VscNotebookTemplate, VscRepoClone, VscFile } from "react-icons/vsc";
import UserInfoIcon from "@rsuite/icons/UserInfo";
import ProjectIcon from "@rsuite/icons/Project";

import { Container, Content } from "rsuite";

function WorkorderInvoicingView() {
  let navigate = useNavigate();
  const [is_loading, set_is_loading] = useState(false);
  const [clients, set_clients] = useState();
  const [client, set_client] = useState(undefined);
  const [selected_project_id, set_selected_project_id] = useState(undefined);
  const [projects, set_projects] = useState();
  const [billing_customer_default_price_list, set_billing_customer_default_price_list] = useState(undefined);
  const [project_default_price_list, set_project_default_price_list] = useState(undefined);
  const [price_lists, set_price_lists] = useState(undefined);
  const [selected_custom_price_list, set_selected_custom_price_list] = useState(undefined);
  // Filters
  const [date_range_from, set_date_range_from] = useState(undefined);
  const [date_range_too, set_date_range_too] = useState(undefined);
  const [selected_billing_customer, set_selected_billing_customer] = useState(undefined);
  const [billing_by_toggle, set_billing_by_toggle] = useState(true);
  const [price_list_group, set_price_list_group] = useState("customer");

  const getClients = useCallback(() => {
    API.get(`/clients`, { params: { limit: 1000 } })
      .then((r) => {
        set_clients(r.data.data.map(({ id: value, name: label }) => ({ value, label })));
      })
      .catch((e) => console.log(e));
  }, []);

  const getPriceLists = useCallback(() => {
    // console.log(id);
    API.get(`/price_lists`)
      .then((r) => {
        set_price_lists((r.data.data = r.data.data.map(({ id: value, name: label }) => ({ value, label }))));
      })
      .catch((e) => console.log(e));
  }, []);

  const getPriceList = (id) => {
    // console.log(id);
    API.get(`/price_lists/${id}`)
      .then((r) => {
        set_billing_customer_default_price_list(r.data);
      })
      .catch((e) => console.log(e));
  };

  const getPriceListSetP = (id) => {
    // console.log(id);
    API.get(`/price_lists/${id}`)
      .then((r) => {
        set_project_default_price_list(r.data);
      })
      .catch((e) => console.log(e));
  };

  const getProjects = useCallback(() => {
    API.get(`/clients/${selected_billing_customer}/projects`)
      .then((r) => {
        set_projects(
          r.data?.map((p) => ({
            value: p.id,
            label: p.locations?.[0]?.address_line_1?.toLowerCase() + " " + p.locations?.[0]?.address_line_2?.toLowerCase(),
          }))
        );
      })
      .catch((e) => console.log(e));
  }, [selected_billing_customer]);

  useEffect(() => {
    getClients();
    getPriceLists();
  }, [getClients, getPriceLists]);

  const handle_billing_cust_select = (e) => {
    set_billing_by_toggle(true);
    if (e) {
      set_selected_billing_customer(e);
      API.get(`/clients/${e}`)
        .then((r) => {
          set_client(r.data);
          if (r.data?.price_list?.id) {
            getPriceList(r.data?.price_list?.id);
            set_price_list_group("customer");
          } else {
            set_price_list_group(undefined);
          }
        })
        .catch((e) => console.log(e));
    } else {
      set_selected_billing_customer(undefined);
      set_client(undefined);
      set_price_list_group("customer");
      set_billing_by_toggle(true);
      set_billing_customer_default_price_list(undefined);
      set_project_default_price_list(undefined);
      set_selected_project_id(undefined);
    }
  };

  const handleDateChange = (values) => {
    if (values && values[0] && values[1]) {
      set_date_range_from(format(values[0], "y-MM-dd"));
      set_date_range_too(format(values[1], "y-MM-dd"));
    } else {
      set_date_range_from(undefined);
      set_date_range_too(undefined);
    }
    // getWorkorders()
  };

  const handle_billing_by_change = (value) => {
    if (value !== billing_by_toggle) {
      set_billing_by_toggle(value);
    }
    if (value !== true) {
      getProjects();
    }
  };

  const hTileGroup = (e) => {
    console.log(e);
    set_price_list_group(e);
  };

  const hProjectSelect = (e) => {
    if (e) {
      set_selected_project_id(e);
      API.get(`/projects/${e}`)
        .then((r) => {
          if (r.data?.price_list?.id) {
            getPriceListSetP(r.data?.price_list?.id);
            set_price_list_group("project");
          }
        })
        .catch((e) => console.log(e));
    } else {
      set_selected_project_id(undefined);
      set_project_default_price_list(undefined);
      if (billing_customer_default_price_list) {
        set_price_list_group("customer");
      } else {
        set_price_list_group("custom");
      }
    }
  };

  const hPriceListSelect = (e) => {
    set_selected_custom_price_list(e);
  };

  const handleSubmit = (e) => {
    var data = {
      date_range_from: date_range_from,
      date_range_too: date_range_too,
      billing_customer: client.id,
      project_id: selected_project_id ?? null,
      price_list_id: undefined,
    };

    if (price_list_group === "customer") {
      data.price_list_id = billing_customer_default_price_list.id;
    }
    if (price_list_group === "project") {
      data.price_list_id = project_default_price_list.id;
    }
    if (price_list_group === "custom") {
      data.price_list_id = selected_custom_price_list;
    }
    console.log(data);

    API.post("/billing/send_to_xero", data)
      .then((r) => window.location.reload())
      .catch((e) => console.log(e.response.data.errors));
  };

  const Ranges = [
    {
      label: "Last 30 Days",
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1))
      ],
    },
  ];

  return (
    <Container>
      <Content>
        <Panel>
          <Stack alignItems="center" justifyContent="space-between">
            <Stack.Item>
              <Heading level={6}>Workorders Billing</Heading>
            </Stack.Item>
          </Stack>
        </Panel>

        <Panel>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Date Range</Form.ControlLabel>
              <DateRangePicker
                style={{ width: 180 }}
                disabled={is_loading}
                character=" → "
                placeholder="Select Date Range"
                ranges={Ranges}
                format="dd-MM-yyyy"
                showOneCalendar
                onChange={handleDateChange}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.ControlLabel>Billing Customer</Form.ControlLabel>
              <SelectPicker disabled={!date_range_from && !date_range_from} loading={!clients} label="Bill to" data={clients ?? []} onChange={handle_billing_cust_select} />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.ControlLabel>Billing by</Form.ControlLabel>
              <RadioGroup name="radio-group" value={billing_by_toggle} disabled={!selected_billing_customer} onChange={(e) => handle_billing_by_change(e)}>
                <Radio value={true}>Customer</Radio>
                <Radio value={false}>Project</Radio>
              </RadioGroup>
            </Form.Group>

            {!billing_by_toggle && (
              <Form.Group controlId="textarea">
                <Form.ControlLabel>Project</Form.ControlLabel>
                <SelectPicker disabled={billing_by_toggle} onChange={hProjectSelect} data={projects ?? []} style={{ width: 224 }} />
              </Form.Group>
            )}

            {selected_billing_customer && (
              <Form.Group>
                <RadioTileGroup color="green" value={price_list_group} inline onChange={hTileGroup}>
                  <RadioTile
                    disabled={!billing_customer_default_price_list}
                    icon={<UserInfoIcon />}
                    label={billing_customer_default_price_list ? billing_customer_default_price_list.name : "Customer Price List"}
                    value="customer"
                  >
                    {billing_customer_default_price_list ? "Customer price list found." : "None found"}
                  </RadioTile>

                  <RadioTile
                    disabled={!project_default_price_list}
                    icon={<ProjectIcon />}
                    label={project_default_price_list ? project_default_price_list.name : "Project Price List"}
                    value="project"
                  >
                    {project_default_price_list ? "Project price list found." : "None found"}
                  </RadioTile>
                  <RadioTile icon={<GearIcon />} label="Other" value="custom">
                    Use another price list
                  </RadioTile>
                </RadioTileGroup>
              </Form.Group>
            )}

            {price_list_group === "custom" && (
              <Form.Group>
                <Form.Group controlId="pl">
                  <Form.ControlLabel>Price List</Form.ControlLabel>
                  <SelectPicker disabled={price_list_group !== "custom"} onChange={hPriceListSelect} loading={!price_lists} data={price_lists ?? []} label="Price List" />
                </Form.Group>
              </Form.Group>
            )}

            <Form.Group>
              <ButtonToolbar>
                <Button type="submit" disabled={!billing_by_toggle && !selected_project_id} appearance="primary">
                  Send to Xero
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Panel>


      </Content>
    </Container>
  );
}

export default WorkorderInvoicingView;
