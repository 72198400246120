import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Table } from "rsuite";

function ConcreteSampleCubeList(props) {
  const { concrete_cubes, is_loading } = props;
  const { Column, HeaderCell, Cell, ColumnGroup } = Table;
  const data = concrete_cubes;

  return (
    <Table
      loading={is_loading}
      height={300}
      minHeight={300}
      className="sample-table-rs"
      hover={true}
      showHeader={true}
      data={data ? data : []}
      cellBordered={true}
      headerHeight={60}
      rowHeight={25}
    >
      <Column width={65}>
        <HeaderCell>#</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="id" />
      </Column>

      <Column width={50}>
        <HeaderCell>Mould</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="mould_number" />
      </Column>
      <Column width={50}>
        <HeaderCell>ID</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="specimen_id" />
      </Column>
      <Column width={50}>
        <HeaderCell>Req Days</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="days_requested" />
      </Column>
      <Column width={25}>
        <HeaderCell>H</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="on_hold" />
      </Column>

      <ColumnGroup header="Pickup">
        <Column width={50}>
          <HeaderCell>By</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="picked_up" />
        </Column>
        <Column>
          <HeaderCell>@</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="picked_up_date">
            {(rowData) => rowData.picked_up_date && format(parseISO(rowData?.picked_up_date), "dd/MM/y")}
          </Cell>
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Curing">
        <Column width={50}>
          <HeaderCell>Initial</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="initial_curing_hours" />
        </Column>
        <Column width={50}>
          <HeaderCell>Type</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="curing_type" />
        </Column>
        <Column width={50}>
          <HeaderCell>At</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="start_lab_curing_date">
            {(rowData) => rowData.start_lab_curing_date && format(parseISO(rowData?.start_lab_curing_date), "dd/MM/y")}
          </Cell>
        </Column>
        <Column width={50}>
          <HeaderCell>Non Std</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="initial_curing_nonstd_reason" />
        </Column>
        <Column width={50}>
          <HeaderCell>By</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="start_lab_curing_by" />
        </Column>
      </ColumnGroup>

      <Column>
        <HeaderCell>Test Date</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="test_date">
          {(rowData) => rowData.test_date && format(parseISO(rowData.test_date), "dd/MM/y")}
        </Cell>
      </Column>

      <Column width={50}>
        <HeaderCell>Cap Type</HeaderCell>
        <Cell style={{ padding: 4 }} dataKey="cap_type" />
      </Column>

      <ColumnGroup header="Measurements">
        <Column width={65}>
          <HeaderCell>Dim 1</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="dimension_1" />
        </Column>
        <Column width={65}>
          <HeaderCell>Dim 2</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="dimension_2" />
        </Column>
        <Column width={65}>
          <HeaderCell>Dim 3</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="dimension_3" />
        </Column>
        <Column width={65}>
          <HeaderCell>Mass</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="mass" />
        </Column>
        <Column width={65}>
          <HeaderCell>Load</HeaderCell>
          <Cell style={{ padding: 4 }} dataKey="load" />
        </Column>
      </ColumnGroup>

      <ColumnGroup header="Results">
        <Column width={65}>
          <HeaderCell>MPa</HeaderCell>
          <Cell style={{ padding: 4, backgroundColor: "#f8f8f8", fontWeight: "bold" }} dataKey="strength" />
        </Column>
        <Column width={65}>
          <HeaderCell>kg/m3</HeaderCell>
          <Cell style={{ padding: 4, backgroundColor: "#f8f8f8", fontWeight: "bold" }} dataKey="density" />
        </Column>
      </ColumnGroup>

      <Column flexGrow={1}>
        <HeaderCell>Comments</HeaderCell>
        <Cell style={{ padding: 4 }} />
      </Column>
    </Table>
  );
}

export default ConcreteSampleCubeList;
